<template>
    <section>
        <q-list padding>
            <q-item clickable v-ripple to="/">
                <q-item-section avatar>
                    <q-icon name="home" />
                </q-item-section>
                <q-item-section>
                    Home
                </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/search">
                <q-item-section avatar>
                    <q-icon name="search" />
                </q-item-section>
                <q-item-section>
                    Book a Vehicle
                </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/apply-as-operator">
                <q-item-section avatar>
                    <q-icon name="directions_car" />
                </q-item-section>
                <q-item-section>
                    Apply as Operator
                </q-item-section>
            </q-item>

            <q-item clickable v-ripple to="/our-policy">
                <q-item-section avatar>
                    <q-icon name="policy" />
                </q-item-section>
                <q-item-section>
                    Privacy Policy
                </q-item-section>
            </q-item>

            <q-item clickable v-ripple to="/about-us">
                <q-item-section avatar>
                    <q-icon name="info" />
                </q-item-section>
                <q-item-section>
                    About Us
                </q-item-section>
            </q-item>
            <q-item clickable v-ripple to="/contact">
                <q-item-section avatar>
                    <q-icon name="contact_mail" />
                </q-item-section>
                <q-item-section>
                    Contact Us
                </q-item-section>
            </q-item>
        </q-list>
    </section>
</template>