<template>
  <section>
    <HomeBannerMobile class="desktop-hide" />
    <SearchEngine />
    <HomeBanner class="desktop-only" />
    <div class="container">
      <div class="q-pa-xl text-center">
        <h4>What is <span class="text-accent">Carbnb</span>?</h4>
        <h6 class="text-grey-7"><span class="text-accent">Carbnb</span> is the Philippine's first and best car rental
          platform committed to providing the lowest price in the market with excellent quality.</h6>
        <div class="row q-col-gutter-md q-mt-md">
          <div class="col-12 col-md-4">
            <q-card class="fixed-height-card q-pa-md" style="border-radius: 25px;" data-aos="fade-down">
              <q-card-section>
                <q-icon name="search" class="text-accent" size="72px" />
                <div class="text-h4 q-mt-lg text-grey-6">Find</div>
                <div class="q-mt-md text-body1">Set pick up location with your rental date</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-12 col-md-4">
            <q-card class="fixed-height-card q-pa-md" style="border-radius: 25px;" data-aos="fade-down">
              <q-card-section>
                <q-icon name="touch_app" class="text-accent" size="72px" />
                <div class="text-h4 q-mt-lg text-grey-6">Select</div>
                <div class="q-mt-md">Select a vehicle available on your pick up location</div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-12 col-md-4">
            <q-card class="fixed-height-card q-pa-md" style="border-radius: 25px;" data-aos="fade-down">
              <q-card-section>
                <q-icon name="no_crash" class="text-accent" size="72px" />
                <div class="text-h4 q-mt-lg text-grey-6">Reserve</div>
                <div class="q-mt-md">Reserve the vehicle and pick it up! Easy!</div>
              </q-card-section>
            </q-card>
          </div>
        </div>
        <div class="row q-mb-xl">
          <div class="col-12">
            <q-btn label="Book Now" color="primary" size="lg" class="full-width q-mt-lg" style="max-width: 650px;"
              rounded to="/search" />
          </div>
        </div>
        <div>
          <h4><span class="text-accent">Wide choices</span> of vehicles!</h4>
          <div class="row q-mt-lg" data-aos="fade-up">
            <div class="col-6 col-md-2 q-pa-xs" v-for="vehicleType in vehicleTypes" :key="vehicleType.name">
              <q-card flat>
                <q-img :src="vehicleType.image">
                </q-img>
              </q-card>
              <div class="text-center text-subtitle1 text-bold text-accent">{{ vehicleType.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeNews />
    <HomeTestimonials />
    <div class="container q-pa-md">
      <div class="row q-mt-lg">
        <div class="col-12 col-md-6 q-pa-md">
          <h5 class="text-accent">Become a Carbnb Operator Now!</h5>
          <h6>Do you want to use your car to earn?</h6>
          <p>
            As a Carbnb operator, you can earn from the comfort of your home.
          </p>
          <p>
            <b>With the demand for rent-a-car getting higher everyday, let us
              help you become one to supply the demand and earn!</b>
          </p>
          <div class="text-center q-py-lg">
            <q-btn color="primary" icon-right="chevron_right" class="btn-actions" label="Get Started"
              to="/apply-as-operator" />
            <ModalOperatorRequirements />
          </div>
        </div>
        <div class="col-12 col-md-6 q-pa-md">
          <img :src="require('@/assets/images/home/carbnb-become-an-operator.jpg')"
            alt="Carbnb Operator Application Benefits" class="red-strip-bg full-width" />
        </div>
      </div>
      <h5>10 Frequently Asked Questions</h5>
      <p>
        We have compiled the frequently asked questions regarding policies,
        pricing, extensions and other general information.<br /><br />Here
        are the 10 things you need to know when renting with
        <span class="text-accent text-bold">Carbnb</span>.
      </p>
      <ul>
        <li>
          An <b>extension</b> like additional 12 hours or 24 hours and more
          must notified to Carbnb at least 48 hours before your original
          booking period ends.
        </li>
        <li>
          For <b>Overtime or Late Returns</b>, you must notify Carbnb at
          least 3 hours before your booking period ends. And we charge
          <b>PHP 300</b> per hour.
        </li>
        <li>
          <b>Standard Overtime:</b> maximum of 3 hours only, but still
          depends if there is no other succeeding booking. If there are more
          than 3 hours of overtime or late, the client must pay the
          equivalent of a day rental or more.
        </li>
        <li>No cash bond and/or security deposit is needed.</li>
        <li>
          When renting for 12 hours, you are only allowed to drive the car
          around Metro Manila.
        </li>
        <li>
          Long range travels like province drive should have a minimum of 24
          hours rental time. Carbnb staff has the rights to assess your
          destination range and might require additional rental time. This
          is due to the chance that the unit can return on time for its next
          booking.
        </li>
        <li><b>Strictly</b> no reservation fee, no booking.</li>
        <li>
          No cancellation and no refund when booking has been made. We can
          only re-schedule bookings on other available dates.
        </li>
      </ul>
    </div>
  </section>
</template>
<script setup>
import HomeBanner from "../components/Home/HomeBanner.vue";
import HomeBannerMobile from "../components/Home/HomeBannerMobile.vue";
import HomeTestimonials from "../components/Home/HomeTestimonials.vue"
import HomeNews from "../components/Home/HomeNews.vue";
import SearchEngine from "@/components/SearchEngine.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue"

const store = useStore();
const route = useRoute();
const router = useRouter();

const vehicleTypes = ref([
  {
    name: "Sedan",
    image: 'SEDAN.webp'
  },
  {
    name: "SUV",
    image: 'SUV.webp'
  },
  {
    name: "Hatchback",
    image: 'HATCHBACK.webp'
  },
  {
    name: "MPV",
    image: 'MPV.webp'
  },
  {
    name: "Pickup",
    image: 'PICKUP.webp'
  },
  {
    name: "Van",
    image: 'VAN.webp'
  }
]);
// If there is a passed qid
if (route.query.qid !== undefined) {
  store.dispatch("qr/transmit", route.query.qid);
  router.replace('/');
}

</script>

<style scoped>
.fixed-height-card {
  height: 250px;
}
</style>