<template>
  <section class="container" style="min-height: 80vh">
    <div class="q-py-md">
      <div class="q-px-md">
        <h5>My Bookings</h5>
      </div>
      <q-tabs v-model="tab" dense class="text-grey" active-color="primary" indicator-color="primary" align="justify"
        narrow-indicator @update:model-value="onTabChange">
        <q-tab name="for_release" label="For Release">
        </q-tab>
        <q-tab name="waiting_for_payment" label="Waiting for Payment">
        </q-tab>
        <q-tab name="closed" label="Closed">
        </q-tab>
      </q-tabs>
      <q-list v-if="!isEmpty">
        <q-item v-if="isLoading">
          <q-card style="min-width: 350px; width: 100%" flat>
            <q-card-section class="q-gutter-sm">
              <q-skeleton type="QText" />
              <q-skeleton type="QText" />
              <q-skeleton type="QText" />
              <q-skeleton type="QToolbar" />
            </q-card-section>
          </q-card>
        </q-item>
        <q-item v-if="isLoading">
          <q-card style="min-width: 350px; width: 100%" flat>
            <q-card-section class="q-gutter-sm">
              <q-skeleton type="QText" />
              <q-skeleton type="QText" />
              <q-skeleton type="QText" />
              <q-skeleton type="QToolbar" />
            </q-card-section>
          </q-card>
        </q-item>
        <div v-if="!isLoading">
          <q-item v-for="booking in bookings" :key="booking.id">
            <q-card style="min-width: 350px; width: 100%">
              <q-card-section>
                <div class="flex justify-between">
                  <span class="text-bold">{{ booking.vehicle_brand }} {{ booking.vehicle_name }}</span>
                  <span>{{ booking.booking_code }}</span>
                </div>
                <div class="flex justify-between q-my-xs">
                  <span>{{ booking.pickup_date }}</span>
                  <q-chip size="12px" style="margin: 0" text-color="white" :color="evaluateChipColor(booking)" dense
                    square>
                    {{ booking.status }}
                  </q-chip>
                </div>
                <div>
                  <span>{{ booking.estimated_return_date }}</span>
                </div>
              </q-card-section>
              <q-card-section>
                <div class="flex justify-between">
                  <div>
                    <q-btn flat round icon="event" />
                    <q-btn flat>
                      {{ booking.duration }}
                    </q-btn>
                  </div>
                  <q-btn flat dense color="primary" :to="'/booking/' + booking.id">
                    View Booking
                  </q-btn>
                </div>
              </q-card-section>
            </q-card>
          </q-item>
        </div>
      </q-list>
      <div v-else class="q-mt-lg text-center">
        <q-icon name="library_books" size="36px" class="text-accent q-pa-md" />
        <p>No bookings found. Would you like to book for a vehicle?</p>
        <q-btn color="primary" class="btn-primary" label="Book a Vehicle" to="/search" icon="add"
          style="max-width: 400px" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useQuasar } from "quasar";

const $q = useQuasar();
const store = useStore();
const isLoading = ref(true);
const isEmpty = ref(false);
const bookings = computed(() => store.state.booking.bookingsByUser);
const tab = ref('for_release');

loadBookings(tab.value)

/**
 * Evaluates the chip color to display for the status.
 */
function evaluateChipColor(booking) {
  if (booking.status === 'WAITING FOR PAYMENT') {
    return 'warning';
  } else if (booking.status === 'ACTIVE') {
    return 'positive';
  } else if (booking.status === 'FOR RELEASE') {
    return 'blue';
  } else if (booking.status === 'COMPLETED') {
    return 'black';
  }
}

function onTabChange(val) {
  isLoading.value = true;
  isEmpty.value = false;
  loadBookings(val);
}

function loadBookings(status) {
  store.dispatch("booking/fetchBookingsFromCustomer",
    {
      user_id: store.state.user.data.id,
      status: status
    }).then((r) => {
      if (r === 'success') {
        isEmpty.value = false;
        isLoading.value = false;
      } else {
        isEmpty.value = true;
        isLoading.value = false;
      }
    }).finally(() => {
      $q.loading.hide();
    });
}
</script>

<style></style>