<template>
  <q-layout view="hHh Lpr lff">
    <q-header bordered class="bg-white text-secondary" height-hint="98" v-if="hasFinishedBooting" style="position: sticky; top: 0;">
      <q-toolbar dense>
        <q-btn dense flat round icon="menu" @click="leftDrawerOpen = !leftDrawerOpen" />
        <q-toolbar-title class="q-py-sm">
          <router-link to="/">
            <img src="@/assets/images/logo.png" class="logo">
          </router-link>
        </q-toolbar-title>
        <UserDropdownMenuComponent v-if="isLoggedIn"/>
        <q-btn v-else outline :dense="$q.screen.lt.lg"
          color="accent" label="Sign In or Register" @click="showSignInModal"/>
      </q-toolbar>

      <q-tabs align="left">
        <q-route-tab to="/search" label="Book Now" />
        <q-route-tab to="/about-us" label="About Us" />
        <q-route-tab to="/contact" label="Contact Us" />
      </q-tabs>
    </q-header>
    <q-drawer show-if-above v-model="leftDrawerOpen" side="left" bordered v-if="hasFinishedBooting">
      <drawer-component/>
    </q-drawer>
    <q-page-container v-if="hasFinishedBooting">
      <router-view />
    </q-page-container>
    <q-footer bordered class="bg-black text-white"
      v-if="hasFinishedBooting && buildPlatform == 'website' && displayFooter"
    >
      <FooterComponent/>
    </q-footer>
    <section style="height: 95vh; width: 100%" v-if="!hasFinishedBooting">
      <div class="column justify-between" style="height: 100%">
        <div></div>
        <div class="column">
          <div class="text-center">
            <img src="@/assets/images/logo.png" style="max-width: 200px">
          </div>
          <div class="text-center q-mt-lg">
            <q-spinner
              color="primary"
              size="3.5em"
              :thickness="5"
            />
          </div>
        </div>
        <div class="text-center">
          Copyright © 2021–2025 Carbnb <br>is owned and operated by CB Auto Solutions Corporation. <br>All rights reserved.
        </div>
      </div>
    </section>
    <q-dialog v-model="userProfileModal" v-if="isLoggedIn">
      <q-card style="min-width: 350px; width: 500px; max-width: 550px">
        <q-card-section class="row items-center q-py-lg">
          <div>
            <div class="text-h6">Your Account</div>
          </div>
          <q-space />
          <q-btn icon="close" flat round dense @click="hideUserProfileModal" />
        </q-card-section>
        <q-card-section class="q-pt-none">
          <UserProfileComponent/>
        </q-card-section>
      </q-card>
    </q-dialog>
    <ModalSignIn :show="signInModal" v-on:close="hideSignInModal"/>
    <ModalUpdateRequired :shown="showUpdateRequiredModal"/>
    <ModalAffiliateInformUser :show="showAffiliateInformDialog" v-on:close="hideAffiliateModal"/>
  </q-layout>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useQuasar } from 'quasar';
import { useStore } from "vuex";
import { boot } from "./core/bootldr";
import { onBootEnd } from "./core/onbootend";

import UserDropdownMenuComponent from "./components/UserDropdownMenuComponent.vue";
import ModalSignIn from "./components/ModalSignIn.vue";
import ModalAffiliateInformUser from "./components/ModalAffiliateInformUser.vue";
import { getDateToday } from "./core/datehelper";
import ModalUpdateRequired from './components/ModalUpdateRequired.vue';
import FooterComponent from "./components/FooterComponent.vue";
import UserProfileComponent from "./components/UserProfileComponent.vue";
import DrawerComponent from "./components/DrawerComponent.vue";

const buildPlatform = process.env.VUE_APP_BUILD_TARGET;
const $q = useQuasar();
const store = useStore();
const leftDrawerOpen = ref(false);
const isLoggedIn = computed(() => store.state.user.isLoggedIn);
const hasFinishedBooting = computed(() => store.state.system.hasFinishedBooting);
const showUpdateRequiredModal = computed(() => store.state.system.isUpdateRequired);
const signInModal = computed(() => store.state.user.isSignInModalShown);
const userProfileModal = computed(() => store.state.user.isUserProfileShown);
const displayFooter = computed(() => store.state.displayFooter);
const showAffiliateInformDialog = computed(() => store.state.showAffiliateForm);

let defaultPickupDate = getDateToday(4);
store.commit('search/setPickupDate', defaultPickupDate);

function showSignInModal() {
  store.commit('user/showSignInModal');
}

function hideSignInModal() {
  store.commit('user/hideSignInModal');
}
function hideUserProfileModal() {
  store.commit('user/hideProfileModal');
}

function hideAffiliateModal() {
  store.commit('toggleAffiliateForm', false);
}

watch(
  hasFinishedBooting,
  (newValue, oldValue) => {
    if (oldValue === false && newValue === true) {
      onBootEnd();
    }
  }
);

boot();
</script>
<style scoped>
    .q-page-container {
      padding-top: 1px !important;
    }

    /* Styles for extra small devices */
    @media (max-width: 599px) {
      .logo {
        max-width: 85px;
      }
    }
    /* Styles for small devices */
    @media (min-width: 600px) and (max-width: 1023px) {
      .logo {
        max-width: 95px;
      }
    }

    /* Styles for medium devices */
    @media (min-width: 1024px) and (max-width: 1439px) {
      .logo {
        max-width: 100px;
      }
    }

    /* Styles for large devices */
    @media (min-width: 1440px) {
      .logo {
        max-width: 115px;
      }
    }

    /* Styles for extra large devices */
    @media (min-width: 1920px) {
      .logo {
        max-width: 120px;
      }
    }
</style>
