export const pullOutTypeOptions = [
    {
        label: 'Temporary',
        name: '0',
    }, 
    {
        label: 'Permanent',
        name: '1',
    }, 
];

export const reasonTypeList = [
    {
        label: 'PMS/Change Oil',
        name: '0',
        type: '0',
    }, 
    {
        label: 'Personal Use',
        name: '1',
        type: '0',
    }, 
    {
        label: 'Repair',
        name: '4',
        type: '0',
    }, 
    {
        label: 'Customer Unsatisfactory',
        name: '2',
        type: '1',
    }, 
    {
        label: 'Collision',
        name: '3',
        type: '1',
    }, 
    {
        label: 'I changed my mind',
        name: '3',
        type: '1',
    }
];