<template>
    <div class="container q-pa-lg">
        <div class="container-fluid text-center">
            <h4>Hear <span class="text-accent">testimonials</span> from our clients!</h4>
        </div>
        <q-virtual-scroll :items="testimonials" virtual-scroll-horizontal v-slot="{ item }" class="q-mt-xl">
            <testimonial-component class="q-mx-md q-my-sm" v-bind:key="item.id" :content="item.content"
                :author="item.author" :thumbnail="item.thumbnail" data-aos="fade-right" />
        </q-virtual-scroll>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";

import TestimonialComponent from '@/components/TestimonialComponent';

const store = useStore();
const testimonials = ref(store.state.system.testimonials);

const fixedTestimonials = ref([
    {
        id: 300,
        content: "<br>Buti na lang may Carbnb para sa mga biglaan kong errands kapag gamit ni Jowa yung isang namin at kapag coding naman yung. Book your car now mga pamangkin ang bait kausap at smooth ka transact ng mga tao Aayytt!",
        author: "TiTo Vince",
        thumbnail: require('@/assets/images/home/carbnb-testimonial-tito-vince.jpg')
    },
    {
        id: 301,
        content: "<br><b>Walang kotse? No problem! Pwede naman mag renta muna.</b><br>Hindi naman ako mayaman at hindi ganon kadami sasakyan ko kaya SALAMAT Carbnb dahil kayo sumasalo sa mga alis ko pag coding ako.",
        author: "Toni Fowler",
        thumbnail: require('@/assets/images/home/carbnb-testimonial-toni-fowler.jpg')
    },
    {
        id: 302,
        content: "<br>Carbnb maraming maraming salamat sa inyo at sa inyong service, ayan pag nag hahanap kayo ng sasakyan sa Carbnb, goods yan!",
        author: "Geo Ong",
        thumbnail: require('@/assets/images/home/carbnb-testimonial-geo-ong.jpg')
    }
]);

testimonials.value = [...testimonials.value, ...fixedTestimonials.value];
</script>

<style></style>