<template>
  <div class="container q-pa-lg">
    <div class="container-fluid text-center">
      <h4>Our <span class="text-accent">participations</span></h4>
    </div>
    <div class="q-pa-md row justify-start content-stretch">
      <!-- Render the latest news article -->
      <div class="col-12 q-pa-sm">
        <q-card flat class="my-card-latest" data-aos="fade-up">
          <q-card-section class="q-pa-none" :horizontal="$q.screen.gt.sm">
            <q-card-section class="col-5 flex flex-center q-pa-none">
              <q-img class="card-image-latest" :src="require('@/assets/images/home/carbnb-taguig-office.jpg')" />
            </q-card-section>

            <q-card-section class="q-pt-md card-content-latest">
              <div class="text-body">
                CB Auto Solutions Corporation, operating as <b class="text-accent">Carbnb</b>, is revolutionizing
                mobility in the Philippines. As
                the leading self- drive car rental platform, we seamlessly connect travelers to nearly 1,000 vehicles
                across 9 strategic locations. With a digital-first approach, we deliver a seamless, hassle-free rental
                experience, setting a new standard for convenience and accessibility in transportation.
                More than just a rental service, <b class="text-accent">Carbnb</b> offers lucrative business programs designed for entrepreneurs
                eager to enter the thriving mobility industry. With a strong foundation of innovation, reliability, and
                sustained growth, <b class="text-accent">Carbnb</b> is your gateway to success in the future of transportation.
              </div>
              <div class="q-py-lg flex-wrap text-center">
                <a href="https://web.facebook.com/Carbnbbooking/" class="q-mx-md" target="_blank"><q-img
                    :src="require('@/assets/images/fb-logo.png')" style="max-width: 50px" /></a>
                <a href="https://www.instagram.com/carbnbph/" class="q-mx-md" target="_blank"><q-img
                    :src="require('@/assets/images/ig-logo.png')" style="max-width: 50px" /></a>
              </div>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-12 col-sm-4 q-pa-sm" v-for="newsItem in news" v-bind:key="newsItem.id" data-aos="fade-down">
        <q-card @click="openSelectedNews(newsItem)" class="my-card">
          <q-card-section class="q-pa-none">
            <q-card-section class="col-5 flex flex-center q-pa-none">
              <q-img class="card-image" :src="newsItem.thumbnail" />
            </q-card-section>

            <q-card-section class="q-pt-md card-content">
              <div class="text-overline">{{ newsItem.publish_date }}</div>
              <div class="text-h6 q-mt-sm q-mb-xs">{{ newsItem.title }}</div>
              <div class="text-caption" v-html="newsItem.content"></div>
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    </div>
    <q-dialog v-model="isFocusNews">
      <q-card>
        <div style="width: 100%" class="row justify-end">
            <q-btn icon="close" flat round dense @click="closeSelectedNews()" />
          </div>
        <q-img :src="selectedNews.thumbnail">
        </q-img>

        <q-card-section style="max-height: 50vh" class="q-pt-md scroll">
          <div class="text-overline">{{ selectedNews.publish_date }}</div>
          <div class="text-h5 q-mt-sm q-mb-xs">{{ selectedNews.title }}</div>
          <div class="text-subtitle2 text-grey" v-html="selectedNews.content"></div>
        </q-card-section>

        <q-card-section class="q-pt-none"> </q-card-section>

        <q-separator />
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "vue";
import { useQuasar } from 'quasar'

const store = useStore();
const $q = useQuasar();
const news = ref(store.state.system.news);
const selectedNews = ref({});
const isFocusNews = ref(false);

function openSelectedNews(newsItem) {
  isFocusNews.value = true;
  selectedNews.value = newsItem;
}

function closeSelectedNews() {
  isFocusNews.value = false;
  selectedNews.value = {};
}

</script>

<style scoped>
.btn-actions {
  min-width: 250px;
  border-radius: 15px;
  height: 50px;
}

.card-content {
  height: 150px;
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-content-latest {
  min-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 30px;
}

.card-image {
  min-height: 300px;
  max-height: 300px;
  max-width: inherit;
}

.card-image-latest {
  min-height: auto;
  max-height: auto;
}

.my-card {
  width: 100%;
}

.my-card-latest {
  width: 100%;
  max-width: 100%;
}

.focused-card {
  width: 700px;
  max-width: 80vw;
}
</style>