<template>
  <section class="container">
    <q-card flat>
      <q-card-section>
        <div class="q-pa-sm">
          <h4 class="text-h4 text-accent">
            <q-icon name="phone" /> Contact Us
          </h4>
          <div class="text-grey-7">
            <span class="text-h6">We would love to hear from you!</span>
            <p>Send us a message and we’ll contact you back.</p>
          </div>
        </div>
        <q-form @submit="sendInquiry">
          <div class="row">
            <div class="col-12 q-pa-sm">
              <q-select v-model="inquiryType" :options="inquiryOptions" map-options emit-value filled
                label="Select Inquiry Type" :rules="[
                  (val) => (val && val.length > 0) || 'Please select an inquiry type.',
                ]" />
            </div>
            <div class="col-12 col-md-6 q-pa-sm">
              <q-input v-model="firstname" filled placeholder="First Name" :rules="[
                (val) =>
                  (val && val.length > 0) ||
                  'Please enter your first name.',
              ]" />
            </div>
            <div class="col-12 col-md-6 q-pa-sm">
              <q-input v-model="lastname" filled placeholder="Last Name" :rules="[
                (val) =>
                  (val && val.length > 0) || 'Please enter your last name.',
              ]" />
            </div>
            <div class="col-12 col-md-6 q-pa-sm">
              <q-input v-model="email" type="email" filled placeholder="Email Address" :rules="[
                (val) =>
                  (val && val.length > 0) ||
                  'Please enter your email address',
              ]" />
            </div>
            <div class="col-12 col-md-6 q-pa-sm">
              <q-input v-model="phone" type="number" filled placeholder="9XXXXXXXXX" prefix="+63" maxlength="10" :rules="[
                (val) =>
                  (val && val.length > 0) ||
                  'Please enter your phone number',
              ]" />
            </div>
            <div class="col-12 q-pa-sm">
              <q-input v-model="message" type="textarea" filled autogrow placeholder="Message"
                :error="generalError != ''" :error-message="generalError" :rules="[
                  (val) =>
                    (val && val.length > 0) ||
                    'Please enter your message or concern.',
                ]" />
            </div>
          </div>
          <div class="text-center">
            <div>
              <q-btn class="btn-primary" rounded icon-right="send" :loading="isLoading" type="submit" label="Send"
                style="min-width: 300px; width:500px" />
            </div>
          </div>
        </q-form>
      </q-card-section>
      <q-card-section class="text-grey-7">
        <br /><b>You can also contact us directly through this mobile number and
          email.</b><br /><br />
        <q-icon name="phone" class="q-mr-sm"></q-icon><a class="text-grey-7" :href="`tel:` + contactNumber">Contact
          Number: {{ contactNumber }}</a>
        <br />
        <q-icon name="email" class="q-mr-sm"></q-icon>
        <a class="text-grey-7" href="mailto:support@carbnbrentals.com">support@carbnbrentals.com</a>
      </q-card-section>
    </q-card>
  </section>
</template>
<style scoped>
.contact-us {
  max-width: 800px;
  margin: auto;
}
</style>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { notify } from "../utils/notification";
import { useRouter } from "vue-router";

const store = useStore();
const email = ref("");
const firstname = ref();

const lastname = ref();
const phone = ref("");
const message = ref();
const isLoading = ref(false);
const router = useRouter();
const contactNumber = ref(store.state.system.contactNumber);
const generalError = ref("");

const inquiryOptions = ref([
  { label: "General Inquiry", value: "1" },
  { label: "Booking Assistance", value: "2" },
  { label: "Need to Rent", value: "3" },
  { label: "Applying as Operator", value: "4" },
  { label: "Partnerships", value: "5" },
  { label: "Others", value: "6" },
]);
const inquiryType = ref(null);

function sendInquiry() {
  if (email.value == "" && phone.value == "") {
    generalError.value = "Either an email address or phone number is required.";
    return;
  }
  generalError.value = "";
  isLoading.value = true;

  const payload = {
    name: firstname.value + " " + lastname.value,
    email: email.value,
    mobile_number: phone.value,
    comment: message.value,
    type: inquiryType.value
  };

  store.dispatch("sendInquiry", payload).then((r) => {
    if (r.status == "success") {
      isLoading.value = false;
      notify(
        "positive",
        "Your inquiry has been sent. We will reach out to you soon.",
        "",
        3000
      );
      router.push("/");
    }
  });
}
</script>
