<template>
  <section>
    <div v-if="!isLoading">
      <div class="banner-header">
        <VehicleBannerComponent/>
      </div>
      <div class="car-details-wrapper">
        <div class="left-content">
          <q-card>
            <q-card-section>
              <h6>Your Rental Dates</h6>
              <p>You can change the rental dates from here to check vehicle availability and pricing.</p>
              <q-list bordered separator>
                <q-item clickable v-ripple @click="showDateSetterComponent = !showDateSetterComponent">
                  <q-item-section>
                    <q-item-label class="text-bold">{{ pickupDate }}</q-item-label>
                    <q-item-label>Pick Up Date</q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-icon color="primary" name="edit" />
                  </q-item-section>
                </q-item>
                <q-item clickable v-ripple @click="showDurationSetterComponent = !showDurationSetterComponent">
                  <q-item-section>
                    <q-item-label class="text-bold">{{ returnDate }}</q-item-label>
                    <q-item-label>Return Date</q-item-label>
                  </q-item-section>
                  <q-item-section avatar>
                    <q-icon color="primary" name="edit" />
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-bold">{{ durationLabel }}</q-item-label>
                    <q-item-label>Rental Length</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item v-if="affiliate.discount && affiliate.is_available">
                  <q-item-section>
                    <q-item-label class="text-bold">{{ affiliate.code }}</q-item-label>
                    <q-item-label>Use this voucher to avail {{ affiliate.discount }} off your booking.</q-item-label>
                  </q-item-section>
                  <q-item-section side top>
                    <q-item-label caption>{{ affiliate.name }}</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <voucher-input v-on:success="handleVoucherSuccess" v-on:clear="handleVoucherSuccess(null)"/>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
            <q-card-section>
              <q-banner rounded class="bg-grey-3" v-if="isCheckingAvailability">
                <q-spinner-rings color="primary" size="3em"/>
                We are checking for the vehicle's availability..
              </q-banner>
              <q-banner rounded class="bg-primary text-white" v-if="!isCheckingAvailability && isAvailable == false">
                <i class="fas fa-calendar-times"></i>
                Unfortunately, the vehicle is not available during those dates.
              </q-banner>
              <q-banner rounded class="bg-positive text-white" v-if="!isCheckingAvailability && isAvailable == true">
                <i class="fas fa-check-circle"></i>
                The vehicle is available during those dates. Please check the pricing below.
              </q-banner>
            </q-card-section>
            <q-card-section v-if="pricing != null && !isCheckingAvailability">
              <h6>Your Rental Price</h6>
              <p>You only need to pay for the reservation fee to reserve the vehicle on these dates. The remaining balance will be collected when the vehicle is picked up.</p>
              <p>For the reservation fee, we currently accept the following payment methods:</p>
              <div class="flex justify-around">
                <img :src="require(`@/assets/images/payment/gcash-logo.png`)" style="width: 125px; height: 125px;"/>
                <img :src="require(`@/assets/images/payment/visa.png`)" style="width: 125px; height: 125px;"/>
                <img :src="require(`@/assets/images/payment/master-card.webp`)" style="width: 125px; height: 125px;"/>
              </div>
              <q-list bordered separator>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-positive">{{ pricing.operator_fee }}</q-item-label>
                    <q-item-label caption>Full Payment Upon Pick Up</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-positive">
                      <span v-if="pricing.discount">
                        <strike class="text-secondary">{{ pricing.reservation_fee }}</strike>
                        {{ pricing.discount.discounted_reservation_fee_with_vat }}
                      </span>
                      <span v-else>
                        {{ pricing.reservation_fee }}
                      </span>
                    </q-item-label>
                    <q-item-label caption>Reservation Fee</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label class="text-positive">
                      <span v-if="pricing.discount">
                        <strike class="text-secondary">{{ pricing.total_rental_fee }}</strike>
                        {{ pricing.discount.discounted_total_rental_fee }}
                      </span>
                      <span v-else>
                        {{ pricing.total_rental_fee }}
                      </span>
                    </q-item-label>
                    <q-item-label caption>Total Rental Fee (Inclusive of VAT and payment gateway fee)</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
            <q-card-section>
                <q-btn
                    label="Book Now"
                    color="primary"
                    class="btn-primary"
                    icon="check_circle_outline"
                    @click="showConfirmation"
                />
                <q-btn
                    label="Select Another Vehicle"
                    icon="arrow_left"
                    color="secondary"
                    class="btn-primary"
                    to="/search"
                />
            </q-card-section>
          </q-card>
        </div>
        <div class="right-content">
          <q-card>
            <q-card-section class="lt-md">
              <div class="q-pb-md text-h5 text-bold">
                {{ vehicle.vehicle_name }}
              </div>
              <div class="car-header-subicons">
                <div class="flex items-center q-my-sm">
                  <q-icon name="fa-solid fa-user q-pr-sm" />
                  {{ vehicle.number_of_seats }} seater
                </div>
                <div class="text-capitalize flex items-center q-my-sm">
                  <q-icon name="fa-solid fa-car q-pr-sm "/>{{ vehicle.type }}
                </div>
                <div class="flex items-center q-my-sm">
                  <q-icon name="fas fa-cogs m-2 q-pr-sm"/>{{ vehicle.transmission }}
                </div>
                <div class="text-capitalize flex items-center q-my-sm">
                  <q-icon name="fa-solid fa-gas-pump q-pr-sm" />{{ vehicle.fuel_type_id }}
                </div>
                <div class="text-capitalize flex items-center">
                  <span class="q-pr-sm"><q-icon name="event_busy"/></span> Coding on {{ vehicle.coding_day }}s
                </div>
              </div>
            </q-card-section>
            <q-card-section>
              <span class="text-h6 text-bold">Pick Up Location</span>
              <div>
                <q-list bordered separator>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-bold">
                        <span v-if="vehicle.yard_id != 0 && vehicle.yard_id != null && vehicle.has_parking == 1">
                          Carbnb Hub
                        </span>
                        <span v-else>
                          Carbnb Partner Operator
                        </span>
                      </q-item-label>
                      <q-item-label>Pick Up Type</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <q-item-label class="text-bold">
                        <span>
                          {{ vehicle.pickup_address }}
                        </span>
                      </q-item-label>
                      <q-item-label>Address</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item>
                    <q-item-section>
                      <iframe
                        :src="
                          'https://maps.google.com/maps?q=' +
                          vehicle.location_latitude +
                          ',' +
                          vehicle.location_longhitude +
                          '&hl=es;z=14&amp;output=embed'
                        "
                        width="100%"
                        height="400px"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </q-item-section>
                  </q-item>
                </q-list>
              </div>
            </q-card-section>
            <q-card-section>
              <span class="text-bold text-h6">Requirement - Bring 2 Primary IDs</span>
              <ul>
            <li>Please be reminded to bring your two primary IDs (including your driver's license) with you when you pick up the car.</li>
            <li>The other primary ID presented will be handed to the assigned Carbnb employee and will be released as soon as you return the vehicle for security purposes.</li>
          </ul>
          <span class="text-bold text-h6"><q-chip color="red" text-color="white" icon="info"/> Late Returns</span>
          <p>Please return on time and estimate your travel time with the best of your ability considering the traffic we have in the Philippines. The failure of the client to return the car to Carbnb for whatever reason within 24 hours from the scheduled deadline shall lead to a penalty of <b>PHP 500,000.00</b> in addition to the acquisition cost of the vehicle and other fees.</p>
          <span class="text-bold text-h6"><q-chip color="red" text-color="white" icon="info"/> Long Travel Advisories</span>
          <br>
          <p>Please be advised that we require a minimum of 2 days rental on long-distance travels. Plan accordingly for a comfortable journey!</p>
          <span class="text-bold text-h6">A driver, friend or relative is picking up the vehicle?</span>
          <br><br>
          <p>If there are other individuals like a driver, friend or relative that will pick up the vehicle for you, please be advised that we will require:</p>
          <ol>
            <li>A duly signed <b>Authorization Letter</b> stating that the client who booked the vehicle is authorizing you to pick up the vehicle on their behalf.</li>
            <li>Driver's license <b>and</b> 1 valid ID (Primary ID) of the person who will pick up the vehicle.</li>
            <li>Two (2) primary valid IDs of the client that is named after the booking.</li>
            <li>The Carbnb team will contact the primary client on the day of the pick up of the vehicle for verification and security purposes.</li>
          </ol>
          <span class="text-bold text-h6">Gas and Toll</span>
          <ol>
            <li>The client needs to replenish the fuel that has been consumed before returning the vehicle. Before you leave the pick up location, please coordinate with your PDI officer and take a photo of the fuel gauge.</li>
            <li>Toll fees are not shouldered by Carbnb. Please reload the RFID accounts if you used the current balance from the vehicle's RFID account.</li>
          </ol>
          <span class="text-bold text-h6">No refunds for the following scenarios:</span>
          <ul>
            <li>If you return earlier than the expected return date.</li>
            <li>For the excess gas and toll fee that you loaded.</li>
          </ul>
          <span class="text-bold text-h6">Booking Policy</span>
          <p class="q-my-md">
            <span class="text-weight-medium">Booking Updates & Extension:</span>
            Upon booking or renting a car. Any form of updates and rent
            extension you wish to be made should be forwarded to our admin. <b>You can extend the unit you rented vehicle as long as there is no subsequent renter.</b> You
            may send a direct message using our contact us page or reach us to
            any of our contact details below:
          </p>
          <b>For immediate support or extensions you can call or text  our 24/7 Customer Service Team here:</b><br><br>
          <q-icon name="email" /> support@carbnbrentals.com<br>
          <q-icon name="phone" /> Landline: 02 (8288-7096)<br>
          <q-icon name="phone_iphone" /> Mobile Number: +63 927 640 0108<br>
          <br />
          <span class="text-bold text-h6">Important Reminders</span>
          <ul>
            <li>Strictly no cancellation or refund. We can only reschedule your booking.</li>
            <li>Strictly no reservation fee, no booking.</li>
            <li>No cash bond/security deposition is needed.</li>
            <li>12 hours rent is strictly within Davao, Cebu and Metro Manila only.</li>
            <li><b>Unli Mileage</b>: 24 hours or more is unli mileage but subject to terms and agreements when taking the vehicle on an interisland travels.</li>
            <li><b>Standard Overtime:</b> Maximum of 3 hours only for <b>PHP 350.00</b> per hour, but still depends if there is no other succeeding booking. If there are more than 3 hours of overtime or late, the client must pay the equivalent of a day rental or more.</li>
          </ul>
          <p class="q-my-md">
            <span class="text-weight-medium">Booking Payment:</span> Please be
            reminded that you are only paying the reservation fee online and
            need to pay the <b>remaining balance upon pickup</b> upon
            picking up the vehicle.
          </p>
          <br />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <div style="height: 50vh" v-else>
      <div class="row justify-center items-center" style="height: 100%; width: 100%;">
        <div class="q-pa-sm text-center">
          <q-spinner
            color="primary"
            size="3em"
            :thickness="5"
          />
          <h5>Hang on..</h5>
        </div>
      </div>
    </div>
    <q-dialog v-model="showDateSetterComponent" :maximized="$q.screen.lt.md">
        <q-card>
          <q-card-section>
            <DateSetterComponent v-on:update="inquire"/>
          </q-card-section>
          <q-card-footer vertical>
            <div class="q-pa-sm">
              <q-btn class="btn-primary" v-close-popup>Confirm</q-btn>
            </div>
          </q-card-footer>
        </q-card>
    </q-dialog>
    <q-dialog v-model="showDurationSetterComponent">
      <q-card>
        <q-card-section>
          <DurationSetterComponent v-on:update="inquire"/>
        </q-card-section>
        <q-card-footer vertical>
          <div class="q-pa-sm">
            <q-btn class="btn-primary" v-close-popup>Confirm</q-btn>
          </div>
        </q-card-footer>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showConfirmationModal" persistent :maximized="$q.screen.lt.md">
      <q-card v-if="!isBooking">
        <q-card-section>
          <h6>Terms and Conditions</h6>
          <p>You are about to book this vehicle. Please read our Terms and Conditions below before proceeding.</p>
          <q-separator/>
          <q-scroll-area
            style="height: 350px; min-width: 350px"
          >
            <BookingTermsComponent/>
          </q-scroll-area>
          <q-separator/>
        </q-card-section>
        <q-card-footer vertical>
          <div class="q-pa-sm">
            <q-checkbox v-model="iAgree" label="By checking this checkbox, you understand and agree to the Terms and Conditions as stated above." />
          </div>
          <div class="q-pa-sm">
            <q-input
              v-model="destination"
              outlined
              placeholder="Enter your destination"
              :error="isDestinationError"
              error-message="Please enter your destination."
              hint="Please declare where you will take this vehicle."
              class="q-my-md"
          />
          </div>
          <div class="q-pa-sm">
            <q-btn class="btn-primary" @click="book" :disabled="!iAgree">Confirm</q-btn>
            <q-btn class="btn-primary" color="secondary" v-close-popup>Cancel</q-btn>
          </div>
        </q-card-footer>
      </q-card>
      <q-card v-else>
        <q-card-section style="height: 30vh">
          <div class="row justify-center items-center" style="height: 100%; width: 100%;">
              <div class="text-center">
                <q-spinner
                color="primary"
                size="3em"
                :thickness="5"
                class="q-mb-md"
              />
              <h5>Processing Booking..</h5>
              <p>Please wait while we process your booking.</p>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </section>
</template>

<script setup>
import axios from 'axios';
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { notify } from "../utils/notification";
import VehicleBannerComponent from "../components/VehicleBannerComponent.vue";
import DateSetterComponent from '../components/SearchEngineComponents/DateSetterComponent.vue';
import DurationSetterComponent from '../components/SearchEngineComponents/DurationSetterComponent.vue';
import VoucherInput from "../components/VoucherInput.vue";
import BookingTermsComponent from '../components/Booking/BookingTermsComponent.vue';

const route = useRoute();
const store = useStore();
const router = useRouter();
const isLoading = ref(false);
const isAvailable = ref(null);
const isCheckingAvailability = ref(false);
const isBooking = ref(false);
const vehicle = ref(null);
const voucher = ref(null);
const pricing = ref(null);
const destination = ref(null);
const showDurationSetterComponent = ref(false);
const showDateSetterComponent = ref(false);
const showConfirmationModal = ref(false);
const isDestinationError = ref(false);
const iAgree = ref(false);
const pickupDate = computed(() => store.state.search.pickupDate.label);
const returnDate = computed(() => store.state.search.returnDate.label);
const durationLabel = computed(() => store.state.search.returnDate.durationLabel);
const affiliate = computed(() => store.state.affiliate);
fetchVehicle();

function fetchVehicle() {
  isLoading.value = true;
  store.dispatch("vehicle/fetchSingleVehicle", route.params.id)
  .then((response) => {
    if (response.status == 'success') {
      vehicle.value = response.data;
      inquire();
    }
  })
  .catch((err) => {
    if (err.response) {
      if (err.response.status == 404) {
        router.push('/search');
      }
    }
  })
  .finally(() => {
    isLoading.value = false;
  });  
}

function handleVoucherSuccess(v) {
  voucher.value = v;
  inquire();
}

function showConfirmation() {
  const isLoggedIn = store.state.user.isLoggedIn;
  if (!isLoggedIn) {
    store.commit('user/showSignInModal');
    return;
  }

  const user = store.state.user.data;
  if (user.mobile_number == null) {
    store.commit('user/showProfileModal');
    notify('negative', 'Please enter your mobile number.');
    return;
  }

  if (user.mobile_number_verified_at == null) {
    store.commit('user/showProfileModal');
    notify('negative', 'Please validate your mobile number thru OTP.');
    return;
  }

  showConfirmationModal.value = true;
}

function inquire() {
  isCheckingAvailability.value = true;

  var payload = {
    vehicle_id: vehicle.value.id,
    pickup_date: store.state.search.pickupDate.value,
    estimated_return_date: store.state.search.returnDate.value
  };

  if (voucher.value) {
    payload.voucher = voucher.value;
  }

  store.dispatch("vehicle/checkAvailability", payload).then((response) => {
    if (response.status == 'available') {
      isAvailable.value = true;
      pricing.value = response.data.pricing;
    } else {
      isAvailable.value = false;
      pricing.value = null;
    }
  })
  .catch((err) => {
    if (err.response) {
      if (err.response.status == 404) {
        router.push('/search');
      }
    } else {
      console.error(err);
    }
  })
  .finally(() => {
    isCheckingAvailability.value = false;
  });
}

function book() {
  isBooking.value = true;

  if (destination.value == '' || destination.value == null) {
    isDestinationError.value = true;
    isBooking.value = false;
    return;
  }

  var payload = {
    vehicle_id: vehicle.value.id,
    pickup_date: store.state.search.pickupDate.value,
    estimated_return_date: store.state.search.returnDate.value,
    destination: destination.value
  };

  if (voucher.value) {
    payload.voucher = voucher.value;
  }

  axios({
    url: "/api/v1/vehicles/book",
    method: "POST",
    data: payload,
  }).then((response) => {
    notify(
      "positive",
      "Your booking application has been accepted and ready for reservation fee payment."
    );
    router.push({
      name: "Booking",
      params: { id: response.data.data.id },
    });
  }).catch(() => {
    //
  });
}
</script>

<style scoped>
.car-details-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  margin: auto;
  background-color: rgb(250, 250, 250);
}
.car-details-wrapper .left-content {
  width: 40%;
  padding: 5px;
}
.car-details-wrapper .right-content {
  width: 60%;
  padding: 5px;
}
</style>